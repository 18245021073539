import { Card, Col, Image, QRCode, Row, Typography } from 'antd';
import { useAppSelector } from '../../app/hooks';
import { invoiceViewTitleStyle } from '../../modules/Money_Receipt/Components/MoneyReceiptDesign';
import { getImageUrl } from '../utils/common.utils';
import WaterMark from './WaterMark';

type Props = {
  qrCodeValue?: string;
};

export const officeInfo = {
  name: '',
  address1: '',
  address2: '',
  modile: '',
};

export const GetInfo = () => {
  const Org_Info = useAppSelector((state) => state.user?.organization_info);
  return {
    logoProp: {
      height: Org_Info?.org_logo_height || 100,
      padding: 10,
      borderRadius: 12,
    },
    currencyName: {
      name: Org_Info?.org_currency,
    },
  };
};

export const LogoStyle = () => {
  const logoHeight = useAppSelector(
    (state) => state.user?.organization_info?.org_logo_height
  );
  return {
    height: logoHeight || 100,
    padding: 10,
    borderRadius: 12,
  };
};

export const InvoiceHeader = ({
  hideWatermark,
}: {
  hideWatermark?: boolean;
}) => {
  const appConfig = useAppSelector((state) => state.configSlice);
  const { tac_wtr_mark } = appConfig;
  const orgInfo = useAppSelector((state) => state.user?.organization_info);
  return (
    <div style={{ marginTop: 10 }}>
      {!hideWatermark && <WaterMark />}
      <Row
        style={{
          fontFamily: "'Source Sans Pro', sans-serif",
          borderBottom: '2px solid #F9F5F6',
        }}
        justify={'space-between'}
        align='middle'
      >
        <Col span={12}>
          <img
            src={getImageUrl(orgInfo?.org_logo)}
            alt={orgInfo?.org_name || 'M360-ICT'}
            style={GetInfo().logoProp}
          />
        </Col>

        <Col span={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <div style={{ marginRight: 5 }}>
              <QRCode
                size={110}
                color='black'
                bordered={false}
                value={`${
                  orgInfo?.org_website ||
                  orgInfo?.org_name ||
                  orgInfo?.org_mobile
                }`}
              />
            </div>
            <div>
              <Typography.Title level={5} style={{ margin: 0 }}>
                {orgInfo?.org_name || ''}
              </Typography.Title>

              {orgInfo?.org_address1 ? (
                <Typography.Text
                  style={{
                    display: 'block',
                    fontSize: '11px',
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  {orgInfo?.org_address2 ? (
                    <>
                      <strong>Address: </strong>
                      <span>{orgInfo?.org_address1}</span>
                    </>
                  ) : (
                    orgInfo?.org_address1
                  )}
                </Typography.Text>
              ) : (
                ''
              )}

              {orgInfo?.org_address2 && (
                <Typography.Text
                  style={{
                    display: 'block',
                    fontSize: '11px',
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  {orgInfo?.org_address2}
                </Typography.Text>
              )}

              <Typography.Text
                style={{
                  display: 'block',
                  fontSize: '11px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                <strong>Mobile:</strong> {orgInfo?.org_mobile}
              </Typography.Text>
              <Typography.Text
                style={{
                  display: 'block',
                  fontSize: '11px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                <strong>Email:</strong> {orgInfo?.org_owner_email}
              </Typography.Text>
              <Typography.Text
                style={{
                  display: 'block',
                  fontSize: '11px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                {orgInfo?.org_extra_info}
              </Typography.Text>

              <Typography.Text
                style={{
                  display: 'block',
                  fontSize: '11px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                {orgInfo?.org_website}
              </Typography.Text>

              <Typography.Text
                style={{
                  display: 'block',
                  fontSize: '11px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                {orgInfo?.org_facebook}
              </Typography.Text>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const TitleCenter = ({ title }: { title: string }) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);

  return (
    <Row justify={'center'}>
      <Typography.Title level={5} style={invoiceViewTitleStyle}>
        {title}
      </Typography.Title>
    </Row>
  );
};

export const ReceiptHeader = ({ qrCodeValue }: Props) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);
  const appConfig = useAppSelector((state) => state.configSlice);

  const qrCodeValueInNotGiven = `
Name:${orgInfo?.org_name}
Address:${orgInfo?.org_address1}
${orgInfo?.org_address2}
Mobile:${orgInfo?.org_mobile}
`;

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {/* Add Watermark on Money Receipt */}
      {appConfig.tac_wtr_mark !== 0 ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            opacity: 0.05,
            zIndex: 1,
            pointerEvents: 'none',
            userSelect: 'none',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '8in',
              height: '100%',
              marginTop: '6%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={
                getImageUrl(appConfig?.tac_wtr_mark_url) ||
                getImageUrl(orgInfo?.org_logo)
              }
              alt={orgInfo?.org_name}
              style={{ maxWidth: 300, userSelect: 'none' }}
            />
          </div>
        </div>
      ) : null}

      <div style={{ flex: 1, flexGrow: 1 }}>
        <img
          style={GetInfo().logoProp}
          src={getImageUrl(orgInfo?.org_logo)}
          alt={orgInfo?.org_name || 'Trabill'}
        />
      </div>

      <div style={{ flex: 1, flexGrow: 1 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 5,
          }}
        >
          <div>
            <QRCode
              size={120}
              style={{
                height: 'auto',
                maxWidth: '100%',
                width: '100%',
                margin: 0,
                padding: 0,
              }}
              bordered={false}
              value={qrCodeValue ? qrCodeValue : qrCodeValueInNotGiven}
            />
          </div>

          <div>
            <Typography.Text
              style={{
                display: 'block',
                fontFamily: "'Source Sans Pro', sans-serif",
                fontWeight: 600,
              }}
              // level={4}
            >
              {orgInfo?.org_name}
            </Typography.Text>
            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_address1}
            </Typography.Text>
            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_address2}
            </Typography.Text>
            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Mobile: {orgInfo?.org_mobile}
            </Typography.Text>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Email: {orgInfo?.org_owner_email}
            </Typography.Text>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_extra_info}
            </Typography.Text>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_website}
            </Typography.Text>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_facebook}
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};
export const PayrollHeader = (props: Props) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);

  return (
    <Card>
      <Row align='middle' justify='center'>
        <Col>
          <img
            style={GetInfo().logoProp}
            src={getImageUrl(orgInfo?.org_logo)}
            alt={orgInfo?.org_name || 'M360 ICT'}
          />
        </Col>
        {/* <Typography.Title level={4}>Trabill</Typography.Title> */}
      </Row>
      <Row align='middle' justify='center'>
        <Typography.Text
          style={{
            display: 'block',
            fontSize: '12px',
            marginRight: '2px',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          {orgInfo?.org_address1}
        </Typography.Text>
        <Typography.Text
          style={{
            display: 'block',
            fontSize: '12px',
            marginRight: '2px',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          {orgInfo?.org_address2 + ',' + ','}
        </Typography.Text>
        <Typography.Text
          style={{
            display: 'block',
            fontSize: '12px',
            marginRight: '2px',
            fontFamily: "'Source Sans Pro', sans-serif",
          }}
        >
          Mobile : {orgInfo?.org_mobile}
        </Typography.Text>
      </Row>
    </Card>
  );
};

export const LoanHeader = (props: Props) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);
  const { Text } = Typography;
  return (
    <>
      <Row justify={'space-between'} align='middle'>
        <Col>
          <Image
            style={{ height: '50px' }}
            src={getImageUrl(orgInfo?.org_logo)}
            alt='Trabill'
          />
        </Col>
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'end',
            textTransform: 'uppercase',
            marginBottom: '20px',
          }}
        >
          <Text className='changeFont'>{orgInfo?.org_name}</Text>
          <Text className='changeFont'>{orgInfo?.org_address1}</Text>
          <Text>
            <Text className='changeFont' strong>
              TEL:
            </Text>
            {orgInfo?.org_mobile} ,
            <Text className='changeFont' strong>
              CALL:
            </Text>
            {orgInfo?.org_mobile}
          </Text>
          <Text>
            <Text className='changeFont' strong>
              Email :
            </Text>
            {orgInfo?.org_owner_email}
          </Text>
          <Text>{orgInfo?.org_extra_info}</Text>
          <Text>
            <Text className='changeFont' strong>
              Website :
            </Text>
            {orgInfo?.org_website}
          </Text>
          <Text>
            <Text className='changeFont' strong>
              Facebook :
            </Text>
            {orgInfo?.org_facebook}
          </Text>
        </Col>
      </Row>
    </>
  );
};
