import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../auth/states/userSlice';
import { toasterNotification } from '../../../common/utils/ToasterNotification';
import {
  DateInput,
  FormButton,
  FormInputItem,
  FormInputTransaction,
  NumberInput,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectDataInput,
  SelectInvoiceFor_V_Payment,
  SelectInvoiceFor_V_Payment_Edit,
  SelectOptionsInput,
  SelectVendors,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import SelectPaymentMethod from '../../../components/common/paymentMethod/SelectPaymentMethod';
import { useLazyGetSingleVendorCombineAmountQuery } from '../api/endpoints/vendorEndpoints';
import {
  useEditVendorPaymentMutation,
  useGetAccountByTypeQuery,
  useLazyGetVPayForEditQuery,
  useLazyGetVendorByInvoiceIdQuery,
  usePostVendorPaymentMutation,
} from '../api/endpoints/vendorPaymentEndpoints';
import AccountLastBalanceInput from '../components/AccountLastBalanceInput';
import {
  IAddVendorPayment,
  ISpecific_Inv_Vendors_Payment,
  presentBalAndColorType,
} from '../types/vendor.interfaces';
import { layout } from './AdvanceReturnAdd';

import { useWatch } from 'antd/es/form/Form';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import PaymentVendorList from '../components/PaymentVendorList';

type Props = {};

const AddVendorPayment = (props: Props) => {
  const { vendorId, vpay_id, id } = useParams();

  const [form] = Form.useForm();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const [postVendorPayment, { data, isSuccess, isError, isLoading, error }] =
    usePostVendorPaymentMutation();
  const [
    editVendorPayment,
    {
      isSuccess: isSuccessEdit,
      isError: isErrorEdit,
      isLoading: isLoadingEdit,
    },
  ] = useEditVendorPaymentMutation();

  const [getVendorByInvoiceId, { data: vendorByInvData }] =
    useLazyGetVendorByInvoiceIdQuery();

  const [presentBalanceLabel, setPresentBalanceLabel] =
    useState<string>('Present balance');

  const [presentBalColor, setPresentBalColor] = useState<
    'green' | 'red' | undefined
  >(undefined);

  const refer = useWatch(['has_refer_passport'], form);
  const payMethodCatId = useWatch(['payment_method_id'], form);
  const lastBalanceId = useWatch(['account_id'], form);
  let com_vendor = useWatch(['com_vendor'], form);

  const [
    getSingleVendorAmount,
    { data: venodrAmounts, isSuccess: vendorAmountSucc },
  ] = useLazyGetSingleVendorCombineAmountQuery();

  const {
    data: accounts,
    refetch: refetchAccount,
    isSuccess: accountSuccess,
    isFetching,
  } = useGetAccountByTypeQuery(Number(payMethodCatId));

  const filteredAccountLastBalance =
    accounts?.data &&
    accounts?.data.find((item) => item.account_id === lastBalanceId);

  const vendorAmount = Number(venodrAmounts?.data);
  const vpay_availeable_balance = filteredAccountLastBalance?.last_balance;
  const max_Amount = useWatch(['maxAmount'], form);

  useEffect(() => {
    refetchAccount();
  }, [payMethodCatId]);

  useEffect(() => {
    if (vendorId) {
      form.setFieldValue('com_vendor', vendorId);
    }
  }, [vendorId]);

  useEffect(() => {
    setFormValue();

    if (vendorAmount) {
      setPresetnBalAndColor('Advance', 'green');
    }
    if (vendorAmount && vendorAmount < 0) {
      setPresetnBalAndColor('Due', 'red');
    }
  }, [vendorAmount, vpay_availeable_balance, com_vendor]);

  useEffect(() => {
    form.setFieldValue('account_id', undefined);
  }, [payMethodCatId]);

  useEffect(() => {
    form.setFieldValue(['vpay_payment_to'], 'VENDOR');
  }, []);

  const setFormValue = () => {
    form.setFieldsValue({
      vpay_present_due: Math.abs(vendorAmount),
      vpay_availeable_balance,
      payment_date: dayjs(),
    });
  };

  const setPresetnBalAndColor: presentBalAndColorType = (balance, color) => {
    setPresentBalanceLabel(balance);
    setPresentBalColor(color);
  };

  // submit handle------------
  const onFinish = async (values: IAddVendorPayment) => {
    delete values?.comb_vendor_specific_invoice;
    delete values?.total_amount;
    values.payment_date = dayjs(values?.payment_date).format('YYYY-MM-DD');
    values.vpcheque_withdraw_date = dayjs(
      values?.vpcheque_withdraw_date
    ).format('YYYY-MM-DD');
    values.created_by = user?.user_id;

    if (!vpay_id) {
      await postVendorPayment(values);
    } else {
      values.id = Number(vpay_id);
      await editVendorPayment(values);
    }
  };

  // ================== notification =========================

  useEffect(() => {
    if (isSuccess || isSuccessEdit) {
      toasterNotification(
        'success',
        `Vendor payment has been ${isSuccess ? 'added' : ''}${
          isSuccessEdit ? 'updated' : ''
        }!`
      );
      if (vpay_id || data?.vpay_id) {
        navigate(`/vendors/payment/${vpay_id || data?.vpay_id}`, {
          state: '/vendors/payment',
        });
      }
      form.resetFields();
    }
    if (isError || isErrorEdit) {
      toasterNotification(
        'error',
        `Vendor payment can not ${isSuccess ? 'added' : ''}${
          isErrorEdit ? 'updated' : ''
        }!`
      );
    }
  }, [isLoading, isLoadingEdit]);

  // ================== vendor select handle==================

  const paymentPrice = useWatch(['payment_amount'], form);
  const onlineCharge = useWatch(['online_charge'], form);
  const aitCharge = useWatch(['vendor_ait'], form);

  useEffect(() => {
    form?.setFieldValue(
      ['total_amount'],
      (Number(paymentPrice) || 0) +
        (Number(onlineCharge) || 0) +
        (Number(aitCharge) || 0)
    );
  }, [paymentPrice, onlineCharge, aitCharge]);

  useEffect(() => {
    form.validateFields(['payment_amount']);
  }, [max_Amount]);

  useEffect(() => {
    if (com_vendor) {
      getSingleVendorAmount(com_vendor);
    }

    if (com_vendor !== 'notCheck') {
      form.setFieldValue(['invoice_id'], undefined);
      form.setFieldValue(['total_amount'], undefined);
      // form.setFieldValue(['vpay_present_due'], undefined);
    }
    com_vendor = 'notCheck';
  }, [com_vendor]);

  let vpay_payment_to = useWatch(['vpay_payment_to'], form);
  useEffect(() => {
    if (vpay_payment_to !== 'notCheck') {
      form.setFieldValue(['com_vendor'], undefined);
      form.setFieldValue(['invoice_id'], undefined);
      form.setFieldValue(['total_amount'], undefined);
      form.setFieldValue(['vpay_present_due'], undefined);
      setPresetnBalAndColor('Present balance', undefined);
    }
    vpay_payment_to = 'notCheck';
  }, [vpay_payment_to]);

  let invoice_id = useWatch(['invoice_id'], form);

  let specific_inv_vendors: ISpecific_Inv_Vendors_Payment[] = useWatch(
    ['specific_inv_vendors'],
    form
  );
  useEffect(() => {
    if (invoice_id !== 'notCheck') {
      form.setFieldValue(['payment_amount'], undefined);
    }

    // @

    getVendorByInvoiceId(invoice_id).then(() => {
      form.setFieldValue(['specific_inv_vendors'], [{}]);
    });
  }, [invoice_id]);

  useEffect(() => {
    // @
    if (specific_inv_vendors?.length) {
      form.setFieldValue(
        ['payment_amount'],
        specific_inv_vendors?.reduce(
          (a, b) => a + Number(b?.specific_inv_amount || 0),
          0
        ) || 0
      );
    }
  }, [specific_inv_vendors]);

  // Vendor payment edit
  const [
    getVendorPayment,
    {
      data: prevVpayData,
      isLoading: prevVpayLoading,
      isSuccess: getDataSuccess,
    },
  ] = useLazyGetVPayForEditQuery();
  const prevData = prevVpayData?.data;

  useEffect(() => {
    if (vpay_id) {
      getVendorPayment(Number(vpay_id));
    }
  }, [vpay_id]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    let timeoutId1: NodeJS.Timeout;
    let timeoutId2: NodeJS.Timeout;

    if (vpay_id && prevVpayData?.success) {
      form.setFieldsValue({
        vpay_payment_to: prevData?.vpay_payment_to,
        cheque_no: prevData?.cheque_no,

        vpcheque_withdraw_date: prevData?.vpcheque_withdraw_date
          ? dayjs(prevData?.vpcheque_withdraw_date)
          : undefined,

        vpcheque_bank_name: prevData?.vpcheque_bank_name,
      });
      timeoutId = setTimeout(() => {
        form.setFieldsValue({
          com_vendor: prevData?.com_vendor,
          invoice_id: prevVpayData?.data?.vpay_invoice_id,
          payment_method_id: prevData?.payment_method_id,
        });
      }, 300);
      timeoutId1 = setTimeout(() => {
        form.setFieldsValue({
          account_id: prevData?.account_id,
          payment_amount: prevData?.payment_amount,
          online_charge: prevData?.online_charge,
          vendor_ait: prevData?.vendor_ait,

          payment_date: prevData?.payment_date
            ? dayjs(prevData?.payment_date)
            : undefined,
          has_refer_passport: prevData?.has_refer_passport,
          vpay_receipt: prevData?.vpay_receipt,
          note: prevData?.note,

          card_trans_charge: prevData?.card_trans_charge,
          card_trans_charge_type: prevData?.card_trans_charge_type,
          card_trans_no: prevData?.card_trans_no,
        });
      }, 500);

      if (vpay_id) {
        timeoutId2 = setTimeout(() => {
          form.setFieldsValue({
            specific_inv_vendors: prevData?.specific_inv_vendors?.map(
              (item) => ({
                comb_vendor_specific_invoice: item.comb_vendor_specific_invoice,
                specific_inv_amount: item.specific_inv_amount,
              })
            ),
          });
        }, 700);
      }
    }
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    };
  }, [vpay_id, prevVpayData, getDataSuccess]);

  // Set vendor name based on id
  const [fetch, setFetch] = useState(false);
  useEffect(() => {
    if (id) form.setFieldsValue({ com_vendor: id });
  }, [id, fetch]);

  return (
    <>
      <BreadCrumb arrOfOption={['Add Payment']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/vendors/payment' state={location.pathname}>
          <Button type='primary' icon={<ArrowLeftOutlined />}>
            Return Vendor Payment List
          </Button>
        </Link>
      </Space>
      {/* <Card> */}
      <div className='p-10'>
        <Form
          layout='vertical'
          style={{ marginTop: '0.5rem' }}
          onFinish={onFinish}
          form={form}
          {...layout}
          labelAlign='left'
        >
          <Row gutter={[5, 5]} justify='center'>
            <Col span={12}>
              <SelectDataInput
                label='Payment To:'
                name='vpay_payment_to'
                disabled={!!vpay_id}
                required
                data={[
                  {
                    id: 'VENDOR',
                    title: 'Over All',
                  },
                  {
                    id: 'INVOICE',
                    title: 'Specific Invoice',
                  },
                ]}
                size={24}
                mdSize={24}
                smSize={24}
                style={{ marginBottom: '20px' }}
              />
              {vpay_payment_to === 'VENDOR' && (
                <SelectVendors
                  name='com_vendor'
                  label='Select vendor'
                  size={24}
                  mdSize={24}
                  smSize={24}
                  required
                  searchText={id?.split('-')[1]}
                  setFetch={setFetch}
                  disabled={vendorId ? true : false}
                />
              )}

              {vpay_payment_to === 'INVOICE' && (
                <>
                  {vpay_id ? (
                    <SelectInvoiceFor_V_Payment_Edit
                      name='invoice_id'
                      label='Select Invoice'
                      required
                      size={24}
                      mdSize={24}
                      smSize={24}
                    />
                  ) : (
                    <SelectInvoiceFor_V_Payment
                      name='invoice_id'
                      label='Select Invoice'
                      required
                      size={24}
                      mdSize={24}
                      smSize={24}
                    />
                  )}
                </>
              )}

              {vpay_payment_to === 'VENDOR' ? (
                <NumberInput
                  label={presentBalanceLabel}
                  name={'vpay_present_due'}
                  color={presentBalColor}
                  readOnly
                  min='0'
                  size={24}
                  mdSize={24}
                  smSize={24}
                  maxChar={14}
                  minChar={0}
                />
              ) : (
                <>
                  <PaymentVendorList
                    form={form}
                    vendorByInvData={vendorByInvData}
                    prevData={prevData}
                  />
                </>
              )}

              <SelectPaymentMethod
                name='payment_method_id'
                label='Payment Method'
                disabled={vpay_id ? true : false}
                cheque
                size={24}
                mdSize={24}
                smSize={24}
              />

              {Number(payMethodCatId) === 5 && (
                <>
                  <AccountLastBalanceInput
                    accountsLastBalance={accounts ? accounts.data : []}
                    name='account_id'
                    label='Account:'
                    required
                    size={24}
                    mdSize={24}
                    smSize={24}
                  />
                  <FormInputTransaction
                    chargeTypeName='card_trans_charge_type'
                    name='card_trans_charge'
                    label='Transaction charge '
                    size={24}
                  />
                  <FormInputItem
                    name='card_trans_no'
                    label='Receipt/Trans No : '
                    size={24}
                  />
                </>
              )}

              {Number(payMethodCatId) === 4 ? (
                <>
                  <FormInputItem
                    label='Cheque No'
                    name='cheque_no'
                    required
                    size={24}
                    mdSize={24}
                    smSize={24}
                  />
                  <DateInput
                    name='vpcheque_withdraw_date'
                    label='Withdraw Date '
                    required
                    size={24}
                    mdSize={24}
                    smSize={24}
                  />
                  <FormInputItem
                    name={'vpcheque_bank_name'}
                    label='Bank Name'
                    required
                    size={24}
                    mdSize={24}
                    smSize={24}
                  />
                </>
              ) : (
                <>
                  {payMethodCatId !== 5 && (
                    <AccountLastBalanceInput
                      accountsLastBalance={accounts ? accounts.data : []}
                      name='account_id'
                      label='Account:'
                      required
                      size={24}
                      mdSize={24}
                      smSize={24}
                    />
                  )}
                </>
              )}

              {payMethodCatId === 4 ? (
                <></>
              ) : (
                <NumberInput
                  label='Available Balance'
                  name='vpay_availeable_balance'
                  color={undefined}
                  readOnly
                  min='0'
                  size={24}
                  mdSize={24}
                  smSize={24}
                  maxChar={14}
                  minChar={0}
                />
              )}

              <NumberInput
                maxAmount={Math.abs(Number(vpay_availeable_balance || 0))}
                name='payment_amount'
                label='Amount:'
                required
                readOnly={vpay_payment_to !== 'VENDOR'}
                dependencies={['invoice_id']}
                size={24}
                mdSize={24}
                smSize={24}
                maxChar={14}
                minChar={0}
                rules={[
                  {
                    validator: async (_, value) => {
                      if (
                        max_Amount &&
                        Number(value || 0) > Number(max_Amount || 0)
                      ) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: 'Insufficient balance!',
                  },
                ]}
              />

              {[3, 2].includes(Number(payMethodCatId)) ? (
                <NumberInput
                  required={false}
                  name='online_charge'
                  label='Transaction Charge'
                  size={24}
                  mdSize={24}
                  smSize={24}
                  maxChar={10}
                  minChar={0}
                />
              ) : (
                <></>
              )}
              <NumberInput
                name='vendor_ait'
                label='Vendor AIT'
                min='0'
                size={24}
                mdSize={24}
                smSize={24}
                maxChar={10}
                minChar={0}
              />

              <NumberInput
                name='total_amount'
                label='Total Amount'
                size={24}
                mdSize={24}
                smSize={24}
                maxChar={10}
                minChar={0}
                readOnly
              />

              <FormInputItem
                name='vpay_receipt'
                label='Receipt/Trans No : '
                size={24}
                mdSize={24}
                smSize={24}
              />

              <SelectOptionsInput
                name='has_refer_passport'
                label='Refer Passport no: '
                options={['Yes', 'No']}
                size={24}
                mdSize={24}
                smSize={24}
              />
              {refer === 'Yes' ? (
                <FormInputItem
                  name='vpaypass_passport_id'
                  label='Select Passports'
                  required
                  size={24}
                  mdSize={24}
                  smSize={24}
                />
              ) : null}

              <DateInput
                name='payment_date'
                label='Date '
                required
                size={24}
                mdSize={24}
                smSize={24}
              />

              <TextAreaInput
                name='note'
                label='Note: '
                size={24}
                mdSize={24}
                smSize={24}
              />

              <FormButton
                mdSize={6}
                smSize={6}
                label={vpay_id ? 'Update' : 'Create'}
                loading={isLoading || isLoadingEdit}
              />
            </Col>
          </Row>
        </Form>

        {(isLoading || prevVpayLoading) && <LoadingIndicator />}
      </div>
    </>
  );
};

export default AddVendorPayment;
